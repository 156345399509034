/* eslint-disable filenames/match-regex */
import React from 'react';
import { TestReleasesDetail } from '../../../components/view/test-releases-detail';
import { ExternalData } from '../../../templates/external-data';
import { ExternalDataProps } from '../../../templates/types';

const TestReleasesDetailPage = (props: ExternalDataProps) => <ExternalData>
  <TestReleasesDetail {...props} />
</ExternalData>;

// eslint-disable-next-line import/no-default-export
export default TestReleasesDetailPage;
